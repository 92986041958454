import React, { useState, ChangeEvent, useEffect } from "react";
// import "./Formulario.css"; // Importe o arquivo de estilo
import Title from "../../components/Title";
import axios from "axios";


const Contato = () => {
  const [titulo, setTitulo] = useState("Contato");

  useEffect(() => {
    document.title = titulo;
  }, [titulo]);

    const [nome, setNome] = useState("");
  // const [empresa, setEmpresa] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [cep, setCep] = useState("");
  const [celular, setCelular] = useState("");
  const [email, setEmail] = useState("");
  const [projeto, setProjeto] = useState<File | undefined>(undefined);

  const [cnpj, setCnpj] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [endereco, setEndereco] = useState("");
  const [telefone, setTelefone] = useState("");
  const [mensagem, setMensagem] = useState("");

  const API = "http://localhost:3000/dwagranitos/dwgranitosphp/index.php";

  useEffect(() => {
    document.title = titulo;
  }, [titulo]);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    enviarDados();
    imprimirDados();
  };

  const imprimirDados = () => {
    console.log({
      nome,
      inscricaoEstadual,
      cidade,
      estado,
      celular,
      email,
      mensagem,
      projeto,
    });
  };

  const enviarDados = () => {
    const formData = new FormData();
    formData.append("nome", nome);
    formData.append("cidade", cidade);
    formData.append("estado", estado);
    formData.append("celular", celular);
    formData.append("email", email);
    formData.append("mensagem", mensagem);
    if (projeto) {
      formData.append("projeto", projeto);
    }

    axios
      .post("https://dwgranitos.com/contato.php", formData)
      .then((response) => {
        if (response.status === 200) {
          alert("Dados enviados com sucesso!");
          setNome("");
          // setEmpresa("");
          setCidade("");
          setEstado("");
          setCelular("");
          setEmail("");
          setProjeto(undefined);
        } else {
          alert("Erro ao enviar os dados. Por favor, tente novamente.");
        }
      })
      .catch((error) => {
        console.error("Ocorreu um erro:", error);
        alert("Erro ao enviar os dados. Por favor, tente novamente.");
      });
  };

  return (
    <>
      <Title title="Contato" />
      <form className="formulario" onSubmit={handleSubmit}>
        <div className="descriptionName">
          <label>
            Nome/Razão Social:
            <input
              type="text"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </label>
        </div>

        <div className="description">
          <label>
            CPF/CNPJ:
            <input
              type="text"
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
            />
          </label>
          <label>
            I.E.:
            <input
              type="text"
              value={inscricaoEstadual}
              onChange={(e) => setInscricaoEstadual(e.target.value)}
            />
          </label>
        </div>

        <div className="descriptionAddress">
          <label>
            Endereço:
            <input
              type="text"
              value={endereco}
              onChange={(e) => setEndereco(e.target.value)}
            />
          </label>
        </div>

        <div className="address">
          <label>
            Cidade:
            <input
              type="text"
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
            />
          </label>
          <label>
            Estado:
            <input
              type="text"
              value={estado}
              onChange={(e) => setEstado(e.target.value)}
            />
          </label>

          <label>
            CEP:
            <input
              type="text"
              value={cep}
              onChange={(e) => setCep(e.target.value)}
            />
          </label>
        </div>

        <div className="contatoPhone">
          <label>
            Telefone:
            <input
              type="text"
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
            />
          </label>
          <label>
            Celular:
            <input
              type="text"
              value={celular}
              onChange={(e) => setCelular(e.target.value)}
            />
          </label>
        </div>

        <div className="descriptionAddress">
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
        </div>
        <div>
          <label>
            Mensagem:
            <textarea
              className="textArea"
              value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}
              rows={4}
            />
          </label>
          <button type="submit">Enviar</button>
        </div>
      </form>
    </>
  );
};

export default Contato;

