const WhatsApp = () => {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdn.positus.global/production/resources/robbu/whatsapp-button/whatsapp-button.css"
      />
      <a
        id="robbu-whatsapp-button"
        target="_blank"
        href="https://api.whatsapp.com/send?phone=5528999511643"
      >
        <div className="rwb-tooltip">Fale conosco</div>
        <img src="https://cdn.positus.global/production/resources/robbu/whatsapp-button/whatsapp-icon.svg" />
      </a>
    </>
  );
};

export default WhatsApp;
