import "./App.css";
import { LanguageProvider, useLanguageContext } from "./LanguageContext";
import Header from "./components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import WhatsApp from "./components/Whatsapp";

function App() {
  const languageContext = useLanguageContext();

  console.log("Idioma ", languageContext.idioma);

  return (
    <LanguageProvider>
      <div>
        <Header />
        <WhatsApp/>
      </div>
    </LanguageProvider>
  );
}

export default App;
